var render = function () {
  var _vm$jobProp;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-hover', {
    attrs: {
      "close-delay": "100",
      "open-delay": "100"
    }
  }, [_c('v-card', {
    staticClass: "ma-4 pa-3",
    attrs: {
      "rounded": "lg",
      "width": "350px",
      "color": "#F9F9F9",
      "to": '/community/jobs/' + _vm.jobProp.url
    }
  }, [_c('v-card-title', [_c('v-avatar', {
    attrs: {
      "color": "primary",
      "size": "56"
    }
  }, [_vm.jobProp.imageUrl ? _c('v-img', {
    attrs: {
      "src": _vm.jobProp.imageUrl
    }
  }) : _c('span', {
    staticClass: "white--text text-h5"
  }, [_vm._v(_vm._s(_vm.companyInitials))])], 1), _c('div', {
    staticClass: "d-flex flex-column ml-5"
  }, [_c('div', {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.jobProp.name))]), _c('div', {
    staticClass: "location"
  }, [_c('span', [_c('v-icon', {
    attrs: {
      "color": "black"
    }
  }, [_vm._v(_vm._s(_vm.mdiMapMarker))])], 1), _c('span', {
    staticClass: "text-body-2"
  }, [_vm._v(" " + _vm._s(_vm.jobProp.locations.join(", ")) + " ")])])]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": "",
      "elevation": "0"
    },
    on: {
      "click": _vm.saveCurrentJob
    }
  }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.saved ? _vm.mdiBookmark : _vm.mdiBookmarkOutline) + " ")])], 1)], 1), _c('v-card-text', [_c('div', {
    staticClass: "text-body-1 font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.jobProp.roleName) + " ")]), _c('div', {
    staticClass: "desc"
  }, [_vm._v(" " + _vm._s(_vm.jobProp.description) + " ")])]), _c('v-card-actions', {
    staticClass: "justify-space-between"
  }, [_c('div', [_c('v-icon', [_vm._v(" " + _vm._s(_vm.mdiBriefcase) + " ")]), _c('span', {
    staticClass: "text-body-2"
  }, [_vm._v(" " + _vm._s(_vm.jobTypePretty((_vm$jobProp = _vm.jobProp) === null || _vm$jobProp === void 0 ? void 0 : _vm$jobProp.jobType)) + " ")])], 1), _c('div', [_c('v-icon', [_vm._v(" " + _vm._s(_vm.mdiSeat) + " ")]), _c('span', {
    staticClass: "text-body-2"
  }, [_vm._v(_vm._s(_vm.jobProp.jobPref.join(", ")) + " ")])], 1), _c('div', [_c('v-menu', {
    attrs: {
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": "",
            "color": "black",
            "dark": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v(" " + _vm._s(_vm.mdiDotsHorizontal) + " ")])], 1)];
      }
    }])
  }, [_c('v-list', [_c('v-list-item', {
    on: {
      "click": _vm.showJobModal
    }
  }, [_c('v-list-item-title', [_vm._v(" View ")])], 1), _c('v-list-item', {
    on: {
      "click": _vm.saveCurrentJob
    }
  }, [_c('v-list-item-title', [_vm._v("Save")])], 1), _c('v-list-item', {
    on: {
      "click": _vm.applyToJobListing
    }
  }, [_c('v-list-item-title', [_vm._v("Apply")])], 1)], 1)], 1)], 1)])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }