var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "hero mb-8"
  }, [_c('v-img', {
    attrs: {
      "src": require("@/assets/images/recruiter/home_banner.png"),
      "max-height": "600px"
    }
  }), _vm.$vuetify.breakpoint.lgAndUp ? _c('span', {
    staticClass: "py-5 hero-btns"
  }, [_c('v-btn', {
    staticClass: "text-none text-body-1 mx-5 font-weight-medium",
    attrs: {
      "color": "primary",
      "elevation": "0",
      "x-large": "",
      "to": "/jobs/create"
    }
  }, [_vm._v(" Post a Job ")])], 1) : _vm._e(), _vm.$vuetify.breakpoint.mdAndDown ? _c('div', {
    staticClass: "py-5 d-flex justify-space-around"
  }, [_c('v-btn', {
    staticClass: "text-none text-body-2 mx-5 font-weight-medium",
    attrs: {
      "color": "primary",
      "elevation": "0",
      "to": "/jobs/create"
    }
  }, [_vm._v(" Post a Job ")])], 1) : _vm._e()], 1), _c('div', {
    staticClass: "d-flex flex-row flex-wrap justify-center"
  }, _vm._l(_vm.displayJobList, function (job) {
    return _c('JobListingCard', {
      key: job.url,
      attrs: {
        "jobProp": job
      }
    });
  }), 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }